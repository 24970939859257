/**
* Gestiona la integración con trazapp
* (c) SIGIS Solulciones integales GIS, C.A
*/
class TrazappSyncApi {

    /**
       * Puede definir los siguientes valores:
       *
       * * contentElemID
       * * clientID
       * * accessToken
       * * trackingSettings :
       *   *
       *
       * @param {object} options
       */
      // Instancia de la librería
      instance;
      obj = {};
      idCon = null;
      constructor(options){
   
       this.options = options;
           //Singleton para garantizar solo una instancia de la clase.
           if (this.instance) {
               console.info('Ya existe una instancia de TrazappSyncApi');
               return;
           }
           this.instance = this;
           // this.init(options);
      }
   
      /**
       * establece y procesa las opciones
       */
       init(cb){
   
           Object.assign(this, this.options);
   
           this.onClean();
               
           this.apiUrl = "https://api-trazapp.sigisqa.com";
           this.syncUrl = "https://trazapp.sigisqa.com/sync";
           this.trazappUrl = "https://trazapp.sigisqa.com/";
           this.currentUrl = window.location.href;
   
           if(!this.clientID){
               this.clientID =  this.getMetaValueByName("trazapp-sync-client_id");
           }
   
           if(!this.accessToken){
               this.accessToken =  this.getMetaValueByName("trazapp-sync-access-token");
           }
   
           if (!this.accessToken && !this.clientID) {
               this.accessToken = this.options?.accessToken;
               this.clientID = this.options?.clientID;
           }
   
           if (!this.accessToken || !this.clientID) {
               console.error('Ocurrió un error obteniendo el accessToken o el clientID');
               return;
           }
   
           this.handleElmClick();
           if(!this.onsync){
               this.onsync = this.getDataAttrById(
                   'trazapp-sync-elem',
                   "data-onsync"
               );
           }
   
           window.addEventListener("message", function(event) {
               if (event && event.data) {
                   this.obj = {
                       trazapp: true,
                       device: event.data,
                       error: null
                   };
               } else {
                   this.obj = {
                       trazapp: true,
                       device: null,
                       error: 'No se logró obtener los datos del dispositivo!'
                   };
               }
   
               this.obj = {
                   ...this.obj,
                   relationId: this.idCon                    
               }
   
               cb(this.obj);
           });
      }
       
      /**
        * Obtiene el ávatar del usuario a través de la api https://ui-avatars.com/api/
        * @method  getAvatar
        * @param name string con el nombre y apellido del usuario
        */
       getAvatar(name) {
           const color = this.phraseColor(name);
           const avatarUrl = `https://ui-avatars.com/api/?background=${color}&length=2&color=fff&name=${name}&format=svg`;
           return encodeURI(avatarUrl);
       }
   
       /**
        * Asigna un color aleatorio para el background del método getAvatar.
        * @method  phraseColor
        */
          phraseColor(phrase) {
           if (!phrase) return;
           const BG_COLORS = [
               '9ddbc9',
               '5b09d0',
               '8504db',
               'ab4bde',
               'ffa500',
               '9acd32',
               '3098b5',
               'debd3c',
               'bd3eef',
               '009eed',
               '71a897',
               'dfe3ab',
               '8cde91',
               '8184ef',
           ];
   
           let ascii = phrase.charCodeAt(0);
           while (ascii >= BG_COLORS.length) {
               ascii = Math.floor(ascii / BG_COLORS.length);
           }
           return BG_COLORS[ascii];
       }
   
      setRelationId(idCon) {
           this.idCon = idCon;
           console.info('Id del contacto seteado para el proceso de la data!');
      };
   
       onClean () {
           localStorage.removeItem('trazapp-sync-id');
           localStorage.removeItem('user');
           localStorage.removeItem('jwt-trazapp');
           localStorage.removeItem('trazapp-sync-emitterChannel');
       }
   
      /**
       * Message receiver
       */
       onMessage(data){
   
           if(typeof this.onsync === "string"){
               if(window[this.onsync]){
                   this.onsync = window[this.onsync]
               }
           }
       }
   
      /**
       * get attr of by id of DomElement
       */
       getDataAttrById(id, attr){
           const div = document.getElementById(id);
           if (!div) return;
           return div.dataset[attr];
       }
   
      /**
       * Obtiene el client id desde un tag meta.
      */
       getMetaValueByName(name){
           const metaElement = document.querySelector(`meta[name='${name}']`)
           if (metaElement && metaElement.content) {
               return metaElement.content;
           }
       }
   
      /**
       * Asocia el evento click al DOM Element con el id indicado
       */
    handleElmClick() {
        setTimeout(() => {
            const me = this;
            const button = document.getElementById('trazapp-sync-elem');
            var iconImage = button.querySelector('#trazapp-icon');

            if (!button) {
                console.error('No existe botón con ID "trazapp-sync-elem"');
                return;
            }
            if (iconImage) {
                const img = document.createElement("img");
                img.setAttribute("src", `${this.trazappUrl}/assets/icons/trazapp-icon.png`);
                img.setAttribute("style", "width: 22px; height: 32px");
                button.appendChild(img);
            }
            // button.setAttribute("style", "padding-top: 5px; display: flex; justify-content: center; align-items: center; margin-top: 5px; margin-right: 5px");


            button.addEventListener("click", function () {
                me.sync()
            });
        }, 300);
    }
   
      /**
       * iniciliza el flujo de sincronización
       */
       sync(){
           const me = this;
   
           me.requestAccess()
           .then((token) => {
               this.syncWindowRef = me.openWindow(`${me.syncUrl}?jwt=${token}`);
           }).catch((error)=>{
               me.onMessage({error})
           })
       }
   
      /**
       * open proportional sizes window
       */
      openWindow(url) {
   
          let defaults = "innerWidth=0.5,innerHeight=0.5,disposition=location";
   
          // Si es móvil
          if (screen.width < 768) {
              defaults = `width=${screen.width},height=${screen.height},disposition=location`
          }
   
          this.windowRef = window.open(`${url}&urlCb=${this.currentUrl}`, "Trazapp Sync", defaults);
      }
   
      /**
       * Gestiona las credenciales de acceso del api de trazapp
       */
       requestAccess(){
           const me = this;
           console.info(`request to [${me.apiUrl}/rpc/request_access]...`)
   
           return new Promise((resolve, reject) => {
               try {
                   fetch(`${me.apiUrl}/rpc/request_access`, {
                       method: "POST",
                       headers: {
                           "accept": "application/json",
                           "Content-Type": "application/json"
                       },
                       body: JSON.stringify({
                           _client_id: me.clientID,
                           _access_token: me.accessToken
                       })
                       })
                       .then(response => response.json())
                       .then(data => {
                           if(data && data.message){
                               throw new Error(data.message)
                           }else if(data && data.token){
                               resolve(data.token)
                           }else{
                               reject("No token found")
                           }
                       })
                       .catch(ex => {
                           reject(ex)
                       });
               } catch (ex) {
                   reject(ex);
               }
           });
       }
   }
   
   window.TrazappSyncApi = new TrazappSyncApi();
   